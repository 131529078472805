<template>
  <b-button
    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
    variant="outline-secondary"
    class="ml-1"
    @click="$router.go(-1)"
  >
    Back
  </b-button>
</template>
<script>
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Back',
  directives: {
    Ripple,
  },
}
</script>
<style lang="scss">

</style>
