<template>
  <validation-observer
    tag="form"
  >
    <b-row>
      <!-- Logo -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Logo"
          rules="max-dimension:1200,1200|image-aspect-ratio:1,1"
        >
          <b-form-group
            label="Logo"
            label-for="logo"
          >
            <b-form-file
              v-model="logoImage"
              accept="image/*"
              :state="getValidationState(validationContext)"
              @input="(event)=>{handleImages(event,'logo')}"
            />
            <b-img
              v-show="isLogoVisible"
              ref="logo"
              :src="logo"
              height="150"
              class="rounded mt-1 mr-2 mb-1 mb-md-0"
            />
            <b-img-lazy
              v-if="!isLogoVisible && logo"
              height="150"
              :src="`${$entitiesImagesURL}${logo}`"
              class="mt-1"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
            <b-button
              v-if="logo && deleteEnabled"
              variant="danger"
              size="sm"
              class="ml-3"
              @click="deleteImage('logo')"
            >
              Delete
            </b-button>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Cover -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Cover"
          rules="image-aspect-ratio:2,1"
        >
          <b-form-group
            label="Cover"
            label-for="cover"
          >
            <b-form-file
              v-model="coverImage"
              accept="image/*"
              :state="getValidationState(validationContext)"
              @input="(event)=>{handleImages(event,'cover')}"
            />
            <b-img
              v-show="isCoverVisible"
              ref="cover"
              :src="cover"
              height="150"
              class="rounded mt-1 mr-2 mb-1 mb-md-0"
            />
            <b-img-lazy
              v-if="!isCoverVisible && cover"
              height="150"
              :src="`${$entitiesImagesURL}${cover}`"
              class="mt-1"
            />
            <b-form-invalid-feedback>
              <div>{{ validationContext.errors[0] }}</div>
            </b-form-invalid-feedback>
            <b-button
              v-if="cover && deleteEnabled"
              variant="danger"
              size="sm"
              class="ml-3"
              @click="deleteImage('cover')"
            >
              Delete
            </b-button>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  props: {
    logo: {
      type: String,
      default: null,
    },
    cover: {
      type: String,
      default: null,
    },
    deleteEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      coverImage: null,
      logoImage: null,
      isLogoVisible: false,
      isCoverVisible: false,
    }
  },
  setup() {
    const { confirmDelete } = handleAlerts()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()
    return {
      refFormObserver,
      getValidationState,
      resetForm,
      confirmDelete,
    }
  },
  methods: {
    handleImages(file, imageType) {
      const selectedImage = file
      this.createBase64Image(selectedImage, imageType)
    },
    createBase64Image(fileObject, imageType) {
      const reader = new FileReader()
      reader.onload = e => {
        if (imageType === 'logo') this.onLogoChange(e.target.result)
        else this.onCoverChange(e.target.result)
      }
      reader.readAsDataURL(fileObject)
    },
    onLogoChange(image) {
      this.setLogoSrc(image)
      this.showLogoImage()
      this.$emit('update:logo', image)
    },
    setLogoSrc(image) {
      this.$refs.logo.src = image
    },
    showLogoImage() {
      this.isLogoVisible = true
    },
    onCoverChange(image) {
      this.setCoverSrc(image)
      this.showCoverImage()
      this.$emit('update:cover', image)
    },
    setCoverSrc(image) {
      this.$refs.cover.src = image
    },
    showCoverImage() {
      this.isCoverVisible = true
    },
    deleteImage(image) {
      this.confirmDelete().then(() => {
        this.$emit(`update:${image}`, '\'\'')
      })
    },
  },
}
</script>

<style>

</style>
