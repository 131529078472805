import { ref } from '@vue/composition-api'
import Vue from 'vue'

export default function locationAutoComplete() {
  let autoComplete = null
  const locationRef = ref(null)

  const fillLocationDetails = (organizationData, locationPoint) => {
    const place = autoComplete.getPlace()
    if (locationPoint.coordinates) {
      locationPoint.coordinates = [place.geometry.location.lat(), place.geometry.location.lng()]
    } else {
      locationPoint.lat = place.geometry.location.lat()
      locationPoint.lng = place.geometry.location.lng()
    }

    place.address_components.forEach(component => {
      const componentType = component.types[0]
      switch (componentType) {
        case 'route':
          Vue.set(organizationData, 'street', `${place.address_components[0].short_name} ${component.short_name}`)
          break
        case 'postal_code':
          Vue.set(organizationData, 'postal_code', component.long_name)
          break
        case 'administrative_area_level_1':
          Vue.set(organizationData, 'state', component.short_name)
          break
        case 'locality':
          Vue.set(organizationData, 'city', component.long_name)
          break
        case 'country':
          Vue.set(organizationData, 'country', component.short_name)
          break
        default:
          break
      }
    })
  }

  const initAutocomplete = (organizationData, locationPoint = organizationData) => {
    // eslint-disable-next-line no-undef
    autoComplete = new google.maps.places.Autocomplete(locationRef.value, {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components', 'geometry'],
      types: ['address'],
    })
    autoComplete.addListener('place_changed', () => { fillLocationDetails(organizationData, locationPoint) })
  }

  return {
    initAutocomplete,
    locationRef,
  }
}
