import {
  computed,
} from '@vue/composition-api'
import store from '@/store'

export default function getGeneralSettings() {
  const getLang = () => store.dispatch('generalSettings/getLanguagesRequest')
  getLang()
  const languages = computed(() => store.getters['generalSettings/getLanguages'])

  const getTags = () => store.dispatch('generalSettings/getTagsRequest')
  getTags()
  const tags = computed(() => store.getters['generalSettings/getTags'])

  const getDonationCategories = () => store.dispatch('generalSettings/getDonationCategoriesRequest')
  getDonationCategories()
  const donationCategories = computed(() => store.getters['generalSettings/getDonationCategories'])

  const activeDonationCategories = computed(() => store.getters['generalSettings/getActiveDonationCategories'])

  const getClass = () => store.dispatch('generalSettings/getClassificationRequest')
  getClass()
  const classifications = computed(() => store.getters['generalSettings/getClassifications'])

  return {
    languages,
    tags,
    activeDonationCategories,
    donationCategories,
    classifications,
  }
}
