import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'

export default function handleAlerts() {
  const confirmExportMode = () => new Promise(resolve => {
    Vue.swal({
      title: 'Please Choose',
      text: 'Please Choose Export with or without session Details?',
      icon: 'question',
      showDenyButton: true,
      confirmButtonColor: '#00A5AF',
      cancelButtonColor: '#d33',
      confirmButtonText: 'With Session Details',
      denyButtonText: 'Without Session Details',
    }).then(result => {
      if (result.isConfirmed) {
        resolve(true)
      } else if (result.isDenied) {
        resolve(false)
      }
    })
  })

  const confirmDelete = (message = null) => new Promise(resolve => {
    Vue.swal({
      title: message ?? 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#00A5AF',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        resolve()
      }
    })
  })

  const successfulOperationAlert = message => {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: message,
        icon: 'BellIcon',
        variant: 'success',
      },
    })
  }

  const failedOperationAlert = message => {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: message,
        icon: 'BellIcon',
        variant: 'danger',
      },
    },
    {
      timeout: 10000,
    })
  }

  const successfulDeletionAlert = () => {
    Vue.swal(
      'Deleted!',
      'Successfully Deleted',
      'success',
    )
  }

  const errorAlert = error => {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: error.toString(),
        icon: 'BellIcon',
        variant: 'danger',
      },
    },
    {
      timeout: 10000,
    })
  }

  const showErrors = errors => {
    const errorsMessage = Object.values(errors)
    errorsMessage.forEach(error => {
      errorAlert(error)
    })
  }

  return {
    successfulOperationAlert, failedOperationAlert, successfulDeletionAlert, showErrors, confirmDelete, confirmExportMode,
  }
}
